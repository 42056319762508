import contact from "../modules/Forms/contact";

contact();


// vertical text scroll
$('.vertical-text').on('click', () => {
    const aboutSection = document.querySelector('.about');
    aboutSection.scrollIntoView()
})

// hero menu
const menu = $('#homeBanner .menu');

$('#hamburgerOpen').on('click', function() {
    menu.addClass('opened');
    menu.attr('tabindex', 1); // turn it focusable

    if ( screen.availWidth > 1100 ) // only in desktop view
        $('.home-banner--content').css({ transform: 'translateX(-100%)' })
})
$('#hamburgerClose').on('click', function() {
    menu.removeClass('opened');
    menu.attr('tabindex', -1); // disable focus

    if ( screen.availWidth > 1100 )
        $('.home-banner--content').css({ transform: 'translateX(0)' })
})

// accordion services
const buttons = document.querySelectorAll('.accordion-button');

function changeAccordionIcon() {
    buttons.forEach((button) => {
        const ionIcon = button.querySelector('.plus-icon');
    
        button.addEventListener('click', () => {
            const isExpanded = button.getAttribute('aria-expanded') === 'true';
    
            buttons.forEach((btn) => {
                const icon = btn.querySelector('.plus-icon');
                icon.setAttribute('name', 'add-outline');
            });
    
            if (isExpanded) {
                ionIcon.setAttribute('name', 'remove-outline');
            } else {
                ionIcon.setAttribute('name', 'add-outline');
            }
        });
    });
}

function changeImageOnAccordionOpen() {
    // accordion open
    $('.accordion-button').on('click', function(){
        let accordionIndex = $(this).closest('.accordion-item').index();
        //src corresponding to the accordion index
        let newImage = $('.wrapper--img img[data-img="' + accordionIndex + '"]');

        if (newImage.length > 0) {
            $('.wrapper--img img:visible').fadeOut(300);
            newImage.fadeIn(300);
        }
    });

    // accordion close
    $('.accordion-collapse').on('hide.bs.collapse', function(){
        $('.wrapper--img img:visible').fadeOut(200);
        $('.wrapper--img img[data-img="default"]').fadeIn(200);
    });
}

changeImageOnAccordionOpen();
changeAccordionIcon();